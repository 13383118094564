<template>
  <router-view/>

</template>

<script>

</script>

<style >
li{
  list-style:none;
}
body{
  margin:0;
  padding:0;
  font-family: PingFang SC,PingFang-SC-Regular,Helvetica Neue,Helvetica,Arial,Microsoft Yahei,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif;

}


</style>