<template>
<div class="main">
  <div class="footerBar">
    <div class="footer-main" v-for="item in footerContent" :key="item.title" >
      <div class="footer-main-title">
       <div class="title">{{item.title}}</div>
       <div class="item" v-for="childItem in item.children" :key="childItem.title">
         <router-link :to=childItem.link class="item">{{childItem.title}}</router-link>
       </div>
      </div>
    </div>

  </div>
  <el-collapse  v-model="activeNames" @change="handleChange" class="collapse" v-for="(item,index)  in footerContent" :key="item.title">
    <el-collapse-item :title=item.title  :name=index >
      <div class="item" v-for="childItem in item.children" :key="childItem.title">
        <router-link :to=childItem.link class="item">{{childItem.title}}</router-link>
      </div>
    </el-collapse-item>
  </el-collapse>
  <div class="footerMore">
    <div class="phoneBar">
      <div class="connnectPhone">联系电话：</div>
      <div class="phoneNumber">13757754696</div>
    </div>
    <div class="emailBar">
      <div class="connnectEmail">业务合作：</div>
      <div class="emailNumber">1228589979@qq.com</div>
    </div>
  </div>
  <div class="footerEnd"><div class="footerEnd-corpyRight">Copyright © 2018-2023 菲舍尔电子科技有限公司 All Rights Reserved&nbsp;&nbsp;&nbsp;&nbsp;</div>
    <a href="https://beian.miit.gov.cn" target="_blank" class="footerEnd-corpyRight">浙ICP备2022005618</a>
  </div>
</div>
</template>

<script>
import {ref} from 'vue';

export default {
  name: "Footer",
  data(){
    return{
      footerContent:[
        {
          title:'首页',
          link:'/',
          children: [
            {
              title:'智慧用电解决方案',
              link:'/smartEleCase'
            }
          ]
        },
        {
          'title':'产品',
          link:'/',
          children: [
            {
              title:'4G通讯协议产品',
              link:'/productCenter'
            },
            {
              title:'Wi-Fi协议产品',
              link:'/productCenter'
            }
          ]
        },
        {
          'title':'加入我们',
          link:'/',
          children: [
            {
              title:'OEM合作',
              link:'/productCenter'
            },
            {
              title:'产品购买',
              link:'/productCenter'
            }
          ]
        },
        {
          'title':'关于',
          link:'/',
          children: [
            {
              title:'公司介绍',
              link:'/aboutUs'
            },
            {
              title:'企业文化',
              link:'/aboutUs'
            },
            {
              title:'联系我们',
              link:'/contactUs'
            }
          ]
        },

      ]
    }
  },
  setup(){
    const activeNames = ref(['1'])
    const handleChange = (val) => {
      console.log(val)
    }
    return{
      activeNames,
      handleChange
    }
  },
}
</script>

<style scoped>
.collapse{
  display:none;
}
.main{
  background-color:rgb(0,0,.85);
}
.footerBar{
  color:#fff;
  padding:15px 20px;
  border-bottom: 1px solid hsla(0,0%,91.8%,.2);
  display:flex;
  justify-content:space-between;
}
.footerBar{
  font-weight:200;

}

.footerBar>h2{
  padding:5px 0 0 0;
  margin:0;
}
.footerMore{
  padding: 20px 20px;
  display:flex;
  justify-content: space-around;
  color:#fff;
  border-bottom: 1px solid hsla(0,0%,91.8%,.2)

}
.footerEnd{
  color: gray;
  font-weight:300;
  padding:10px 10px 5px 10px;
  line-height:16px;
  text-align: center;
  background-color:#212121;
}
.footerEnd-corpyRight{
  font-weight:300;
  font-size:12px;
  color: gray;
}
.emailNumber{
  padding:.5rem 0;
  font-size: 1.5rem;
  font-weight:400;
}
.phoneNumber{
  padding:.5rem 0;
  font-size: 1.5rem;
  font-weight:400;
}
a{
  text-decoration:none;
  color:#fff;
}
.footer-main{
  padding: 10px 70px;
  font-size:15px;
  font-weight:800;
}
.item{
  font-size:13px;
  font-weight:300;
  text-align:left;
  padding:10px 0 0 0
}
.item:hover{
  color:rgb(255, 220, 49);
  cursor:pointer;
}
/deep/.collapse {
  --el-collapse-border-color: var(--el-border-color-lighter);
  --el-collapse-header-height: 48px;
  --el-collapse-header-bg-color: rgb(0,0,.85);
  --el-collapse-header-text-color: var(--el-text-color-primary);
  --el-collapse-header-font-size: 16px;
  --el-collapse-content-bg-color: rgb(0,0,.85) !important;
  --el-collapse-content-font-size: 13px;
  --el-collapse-content-text-color: var(--el-text-color-primary);
  border-top: 1px solid var(--el-collapse-border-color);
  border-bottom: 1px solid var(--el-collapse-border-color);
}
@media screen and (max-width:500px){
  .emailNumber{
    padding:.5rem 0;
    font-size: 1rem;
    font-weight:300;
  }
  .phoneNumber{
    padding:.5rem 0;
    font-size: 1rem;
    font-weight:300;
  }
  a{
    text-decoration:none;
    color:#fff;
    font-weight:200;
    padding:10px 0 0 0 ;
    display:block;
    height:30px;
  }
  /deep/.collapse {
    --el-collapse-border-color: hsla(0,0%,91.8%,.2);
    --el-collapse-header-height: 48px;
    --el-collapse-header-bg-color: rgb(0,0,.85);
    --el-collapse-header-text-color: var(--el-text-color-primary);
    --el-collapse-header-font-size: 15px;
    --el-collapse-content-bg-color: rgb(0,0,.85) !important;
    --el-collapse-content-font-size: 15px;
    --el-collapse-content-text-color: var(--el-text-color-primary);
    border-top: 1px solid var(--el-collapse-border-color);
    border-bottom: 1px solid var(--el-collapse-border-color);
  }
  /deep/.el-icon{
    color:#fff;
    font-weight:800;
  }
  .footer-main{
    display:none;

  }
  .footerBar{
    padding:10px 0 0 0 ;
    border:none;
    display:none;
  }

  .item{

    font-size:13px;
    font-weight:400;
    text-align:left;
    padding:.5rem 0 0 .5rem;
    color: #c4c1c1;
  }
  /deep/.el-collapse-item__header{
    padding:0 0 0 1rem;
    font-size:16px;
    font-weight:800;
    color:white;
  }
  .item:hover{
    color:rgb(255, 220, 49);
    cursor:pointer;
  }
  .footerMore{
    justify-content:space-between
  }
  .collapse{
    display:block;
  }
}
</style>