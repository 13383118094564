<template>
<banner componentName="联系我们"></banner>
  <div class="main">
    <div class="img">
      <img src="/img/ContactUs.jpeg" alt="">
    </div>
    <div class="detail">
      <div class="title">联系我们</div>
      <div class="detail-more">
        <div class="phone"><i class="iconfont">&#xe725;</i>电话： 13757754696</div>
        <div class="email"><i class="iconfont">&#xe66f;</i>邮箱： 1228589979@qq.com</div>
        <div class="qq"><i class="iconfont">&#xe882;</i>Q Q:  &nbsp; &nbsp;1228589979</div>
        <div class="add"><i class="iconfont">&#xe62b;</i>地址： 浙江省温州市乐清市柳市镇柳南村龙峰路42弄5号</div>
      </div>
    </div>
  </div>
  <Footer></Footer>
</template>

<script >
import banner from "@/components/banner";
import Footer from '@/components/Footer'
export default {
  name: "ContactUs",
  components:{
    banner,
    Footer
  }
}

</script>

<style scoped>
.img{
  height:500px;
  width:100%;
  margin:0;
  padding:0;
}
.img>img{
  width:100%;
  height:500px;
}
.title{
  width:200px;
  height:40px;
  font-size:28px;
  margin:20px auto;
  letter-spacing:2px;
}
.detail-more{
  width:20rem;
  height:200px;
  margin: 10px auto;
  padding:5px;
}
.detail-more{
  line-height:2;

}
.detail-more>div>i{
  padding:0 5px;
}
@media screen and (max-width:500px){
  .main{
    padding:70px 0 0 0 ;
  }
  .img>img{
    width:100%;
    height:15rem;
  }
  .img{
    height:15rem;
    width:100%;
    margin:0;
    padding:0;
  }
  .title{
    width:200px;
    height:1.5rem;
    font-size:1.5rem;
    margin:20px auto;
    letter-spacing:2px;
    line-height:1.5rem;
    text-align: center;
  }
  .add{
    word-break: break-all;
  }
}
</style>