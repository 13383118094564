import { createApp } from 'vue'

import App from './App.vue'

import '/src/assets/iconfont/iconfont.css'
import router from './router'
import FastClick from 'fastclick'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
FastClick.attach(document.body)

createApp(App).use(router).use(ElementPlus).mount('#app')
