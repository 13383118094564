<template>
  <div class="content">
    <swiper :autoplay="swiper_options.autoplay" :navigation="true"  :loop="swiper_options.loop" :speed="swiper_options.speed" :pagination="swiper_options.pagination" :scrollbar="{ draggable: true }">
      <swiper-slide >
        <div class="img-cus">
          <img  class="swiper-img" src="/img/banner1.png" alt="">
        <div class="img-cus-text">
          <div class="img-cus-text-1">全球化智能电器制造商</div>
          <div class="img-cus-text-2">大量成熟智能产品，助力智能用电快速落地</div>
          <div class="img-cus-text-3">
            <div><i class="iconfont">&#xe622;</i>智能开关</div>
            <div><i class="iconfont">&#xe619;</i>智能电器 <br> </div>
            <div><i class="iconfont">&#xe616;</i>安放传感</div>
            <div><i class="iconfont">&#xe618;</i>电工照明</div>
          </div>
        </div>
      </div></swiper-slide>
      <swiper-slide ><div class="img-cus"><img class="swiper-img" src="/img/banner2.png" alt="">
        <div class="img-cus-banner2-text">
          <div class="img-cus-banner2-text-1">大量智能产品，到产品中心</div>
          <div class="img-cus-banner2-text-2">专享功能定制，快速智能互联服务</div>
          <div class="img-cus-banner2-text-3">
           <router-link to="/productCenter"><div>点击查看</div></router-link>
          </div>
        </div>
      </div></swiper-slide>
      <swiper-slide><div class="img-cus"><img class="swiper-img" src="/img/banner3.jpg" alt=""></div></swiper-slide>
      <swiper-slide>
        <div class="img-smartEleCase">
        <img class="swiper-img-smartEleCase" src="/img/smartEleCase/banner2.jpeg" alt="">
          <div class="img-cus-banner2-text">
            <div class="img-cus-banner2-text-1" style="color:#000">智慧用电解决方案</div>
            <div class="img-cus-banner2-text-2" style="color: #606266;">专享功能定制，快速智能互联服务</div>
            <div class="img-cus-banner2-text-3">
              <router-link to="/smartEleCase"><div>点击查看</div></router-link>
            </div>
          </div>
      </div></swiper-slide>
    </swiper>

  </div>
  <div class="banner2">
    <div class="banner2-1">
      <i class="iconfont banner-font banner-font-1">&#xe63f;</i>
      <i class="iconfont  banner-font banner-font-2">&#xe611;</i>
      <i class="iconfont  banner-font banner-font-3">&#xe61e;</i>
      <i class="iconfont  banner-font banner-font-4">&#xe67a;</i>
      <i class="iconfont  banner-font banner-font-5">&#xe7b5;</i>
      <i class="iconfont banner-font banner-font-6">&#xec78;</i>
    </div>
  </div>
<div class="main animate__animated animate__backInUp">
  <div class="solution-main">
    <div class="solution-title">探索智能用电更多可能</div>
  </div>
  <ul>
    <li  class="ul-li">
      <swiper  class="swiper-content" :autoplay="swiper_options_content.autoplay" :slidesPerView="swiper_options_content.slidesPerView" :loop="swiper_options_content.loop" :speed="swiper_options_content.speed" :pagination="swiper_options_content.pagination" :scrollbar="{ draggable: true }">
        <swiper-slide class="swiper-slide-content" v-for="item in products" :key="item">
          <router-link :to="'/products?id='+item.id" >
            <div class="swiper-div">
              <img class="swiper-img-content" :src="item.cover" alt="">
              <div class="title">{{item.title}}</div>
              <div class="more">
                了解详情<i class="iconfont">&#xe88e;</i>
              </div>
            </div>

          </router-link>
        </swiper-slide>
      </swiper>
    </li>
  </ul>
</div>
  <div>
    <div class="globalize-main animate__animated animate__bounceInUp">
      <div class="globalize-title">
        <div class="globalize-title-title">全球化用户</div>
        <div class="globalize-flex">
          <div><div class="globalize-number">200+ &nbsp;</div><div class="globalize-description">用户分布国家和地区</div></div>
          <div><div class="globalize-number">5分钟 &nbsp;</div><div class="globalize-description">完成用电智能化</div></div>
          <div><div class="globalize-number">1分钟 &nbsp;</div><div class="globalize-description">完成数据上云</div></div>
        </div>
      </div>
      <div class="globalize-map">
        <img src="/img/map.png" alt="">
        <div class="globalize-localization-elg"><div class="globalize-localization-dot"></div></div>
        <div class="globalize-localization-elg-text">俄勒冈</div>
        <div class="globalize-localization-fjly"><div class="globalize-localization-dot"></div></div>
        <div class="globalize-localization-fjly-text">弗吉利亚</div>
        <div class="globalize-localization-flkf"><div class="globalize-localization-dot"></div></div>
        <div class="globalize-localization-flkf-text">法兰克福</div>
        <div class="globalize-localization-amstd"><div class="globalize-localization-dot"></div></div>
        <div class="globalize-localization-amstd-text">阿姆斯特丹</div>
        <div class="globalize-localization-sh"><div class="globalize-localization-dot"></div></div>
        <div class="globalize-localization-sh-text">上海</div>
      </div>
    </div>
  </div>

</template>

<script>
import SwiperCore, {Autoplay,Pagination,Navigation} from 'swiper';
import {Swiper,SwiperSlide} from 'swiper/vue';
import 'swiper/swiper.min.css';
import {reactive} from "vue";
SwiperCore.use([Autoplay,Pagination,Navigation]);


export default {
  name: "Content",
  components: {
    Swiper,
    SwiperSlide
  },
  data(){
    return {
      products:[
        { id:'1',
          cover:'/img/product-1/product1_cover.png',
          title:'WIFI重合闸电源保护器'
        },
        {id:'2',
          cover:'/img/product-2/4Gproduct_cover.png',
          title:'重合闸电源保护器(4G版)'
        },
        {id:'3',
          cover:'/img/product-3/product3_cover.png',
          title:'WIFI开关'
        },
        {id:'4',
          cover:'/img/product-4/product4_cover.png',
          title:'4P自动重合闸'
        },
        {id:'5',
          cover:'/img/product-5/product5_cover.png',
          title:'自动重合闸'
        },
        {id:'6',
          cover:'/img/product-6/product-cover.jpg',
          title:'4G四路智能断路器'
        },
        {id:'7',
          cover:'/img/product-7/product-cover.png',
          title:'4G智能断路器三相'
        },


      ]
    }
  },
  setup() {
    // swiper相关配置属性放在swiper_options这个变量里
    let swiper_options = reactive({
      autoplay:{
        delay:3000,
        disableOnInteraction: false
      },
      loop:true,
      speed:2000,
      pagination:{
        el:'.swiper-pagination',
        clickable: true,
      }
    })
    // 将swiper_options返回给模板中的swiper组件使用

  let swiper_options_content = reactive({
    autoplay:{
      delay:0,
      disableOnInteraction: false
    },
    loop:false,
    speed:7000,
    slidesPerView:'auto',
    pagination:{
      el:'.swiper-pagination',
      clickable: true,
    }
  })
  // 将swiper_options返回给模板中的swiper组件使用
  return {swiper_options,swiper_options_content};
},

}
</script>

<style scoped>
.main>ul{
  display:flex;
  justify-content:space-between;
  flex-flow:wrap;
  padding:0 1rem;
  background-color: white;

}
.ul-li{
  height:26rem;
  width:100%;
  margin: 0 0 20px 0;
  /*box-shadow: rgba(14, 30, 37, 0.03) 0px 2px 4px 0px, rgba(14, 30, 37, 0.08) 0px 2px 16px 0px;*/
  background:white;
  cursor:pointer;
  transition: all 0.4s;

}

.ul-li>a>img{
  height:300px;
  width:100%;

}
.title{
  margin:0 auto;
  color:rgba(0,0,0,.65);
  text-align: left;
  font-weight: bold;
  font-size: 16px;
  color:#041B3D;
  padding: 10px 20px;
}

.content{
  height:400px;
  width:100%;
  margin:0;
  padding:60px 0 0 0 ;
}
.swiper-img{
  width:100%;
  height:500px;
}
li{
  list-style:none;
}
a{
  text-decoration:none;
}
.more{
  width: 90%;
  text-align: left;
  padding: 6px 20px;
  font-size: 14px;
  color: #1B2126;
  display: inline-block;
  transition: all .5s;
  opacity: 0.5;
  line-height:14px;
}
.more>i{
  font-size: 14px;
  line-height:14px;
  font-weight: bolder;
}
.swiper-slide-content{
  height:27rem;
  width:25%;
}
.img-cus{
  position:relative;

}
.img-cus-text{
  position:absolute;
  top:35%;
  left:20%;

  z-index:10000000;
}
.img-cus-text-1{
  font-size:40px;
  color:#fff;
  line-height:35px;
  text-align: left;
  font-weight: 700;
  letter-spacing:7px;
}
.img-cus-text-2{
  padding:20px 0 0 0;
  font-size:20px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-text-3{
  width:350px;
  display:flex;
  flex-wrap:wrap;
  padding:20px 0;
  font-size:18px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-text-3>div{
  padding:20px 20px 20px 0;
}


.img-cus-banner2-text{
  position:absolute;
  top:35%;
  left:20%;

  z-index:10000000;
}
.img-cus-banner2-text-1{
  font-size:40px;
  color:#fff;
  line-height:35px;
  text-align: left;
  font-weight: 700;
  letter-spacing:7px;
}
.img-cus-banner2-text-2{
  padding:20px 0 0 0;
  font-size:20px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-banner2-text-3{
  width:350px;
  display:flex;
  flex-wrap:wrap;
  padding:20px 0;
  font-size:18px;
  color:#fff;
  line-height:30px;
  text-align: left;
  font-weight: 400;
  letter-spacing:3px;
}
.img-cus-banner2-text-3>a{
  padding:20px 20px 20px 0;
  background-color:#ff4800;
  color:#fff;
  width:90px;
  height:10px;
  border: none;
  border-radius: 30px;
  line-height:10px;
  text-align:center;
}
.img-cus-banner2-text-3:hover{
  opacity:.8;
}
.img-cus-banner2-text-3>a>div{
  line-height:10px;
  text-align:center;
  width:100%;
  margin-left:10%;
}

.swiper-img-smartEleCase{
  width:70%;
}
.swiper-div{
  height:24rem;
  border: 1px solid #f0f0f1;
  margin:16px 19px;

}
.swiper-div:hover{
  height:24rem;
  border: 1px solid #ac2f41;
  color:#ac2f41;

}
.swiper-content{
  height:27rem;

}
.swiper-img-content{
  height:16rem;
  width:87%;
  padding:20px;
  transition: all .8s;

}
.swiper-img-content:hover{
 transform:scale(1.1)

}
.banner2{
  background-color:#f5f5f5;
  margin: 100px 0 0 0px;
}
.banner2-1{
  padding:20px;
  display:flex;
  justify-content:space-between;
  flex-wrap: wrap;
}
.banner-font{
  line-height:47px;
  text-align:center;
  padding:20px 25px 39px 25px;
  font-size:50px;
  font-weight: 100;
  color:rgba(0,0,0,.65);
  position:relative;
  border-radius: 10px;
  margin:10px 0;
  transition: all .8s;
  background-color:white
}
.banner-font:hover{
  transform:scale(1.1)
}
.banner-font-1::before{
  content: "定时开关";
  font-size:15px;
  position:absolute;
  top:62px;
  right:21px;
  font-weight: 400;
  text-align: center;


}
.banner-font-2::before{
  content: "智能插座";
  font-size:15px;
  position:absolute;
  top:62px;
  right:21px;
  font-weight: 400;
  text-align: center;


}
.banner-font-3::before{
  content: "智能重合闸";
  font-size:15px;
  position:absolute;
  top:62px;
  right:15px;
  font-weight: 400;
  text-align: center;


}
.banner-font-4::before{
  content: "智能断路器";
  font-size:15px;
  position:absolute;
  top:62px;
  right:15px;
  font-weight: 400;
  text-align: center;


}
.banner-font-5::before{
  content: "三相断路器";
  font-size:15px;
  position:absolute;
  top:62px;
  right:15px;
  font-weight: 400;
  text-align: center;


}
.banner-font-6::before{
  content: "小型断路器";
  font-size:15px;
  position:absolute;
  top:62px;
  right:15px;
  font-weight: 400;
  text-align: center;


}
.banner-font:hover{
 color: #5d9a9c;

}
.solution-main{
  height:80px;
  width:80%;
  margin:30px auto;
  background-color:white;
}
.solution-title{
  font-size:36px;
  font-weight:700;
  line-height:80px;
  text-align: center;
}
.globalize-main{
  background-color:#f6f7f8;
  width:100%;
}
.globalize-map>img{
  width:80%;
  height:500px;
  margin:0 10%;
  padding-bottom:80px;
  position:relative;
}
.globalize-title-title{
  padding:80px;
  height:80px;
  font-weight: 700;
  line-height:80px;
  text-align: center;
  font-size:36px;
}
.globalize-flex{
  display:flex;
  justify-content:center;
}
.globalize-flex>div{
  width:30%;
  display:flex;
  justify-content:center;
  padding-bottom: 80px;
}
.globalize-number{
  color:#5831ec;
  font-weight: 600;
  font-size: 24px;
}
.globalize-description{
  font-size: 20px;
  font-weight:200;
  line-height:36px;
  text-align: center;
}
.globalize-localization-elg{
  position:absolute;
  left:20%;
  top:60%;
  width:10px;
  height:10px;
  border:1px solid rgba(255, 72, 0, 0.5);
  border-radius: 50%;
  background-color:rgba(255,72,0,.2);
  animation:2s localization infinite;
}
.globalize-localization-fjly{
  position:absolute;
  left:30%;
  top:62%;
  width:10px;
  height:10px;
  border:1px solid rgba(255, 72, 0, 0.5);
  border-radius: 50%;
  background-color:rgba(255,72,0,.2);
  animation:2s localization infinite;
}
.globalize-localization-flkf{
  position:absolute;
  left:50%;
  top:55%;
  width:10px;
  height:10px;
  border:1px solid rgba(255, 72, 0, 0.5);
  border-radius: 50%;
  background-color:rgba(255,72,0,.2);
  animation:2s localization infinite;
}
.globalize-localization-amstd{
  position:absolute;
  left:48.5%;
  top:53%;
  width:10px;
  height:10px;
  border:1px solid rgba(255, 72, 0, 0.5);
  border-radius: 50%;
  background-color:rgba(255,72,0,.2);
  animation:2s localization infinite;
}
.globalize-localization-sh{
  position:absolute;
  left:74%;
  top:64%;
  width:10px;
  height:10px;
  border:1px solid rgba(255, 72, 0, 0.5);
  border-radius: 50%;
  background-color:rgba(255,72,0,.2);
  animation:2s localization infinite;
}
@keyframes localization {
  20%{
    transform:scale(1.1);
    border:1px solid rgba(255, 72, 0, 0.4);

  }
  40%{
    transform:scale(1.2);
    border:1px solid rgba(255, 72, 0, 0.2);
  }
  80%{
    transform:scale(1.3);
    border:1px solid rgba(255, 72, 0, .1);
  }

}
.globalize-localization-dot{
  left:20%;
  top:60%;
  width:5px;
  height:5px;
  border-radius: 50%;
  background-color:rgba(255, 72, 0, 1);
  margin:25%;
}
.globalize-localization-elg-text{
  position:absolute;
  left:21.2%;
  top:59.64%;
  color:#606266;
  opacity:.8;
  font-size: 14px;
  line-height:18px;
  font-family: Lato,Lato-Regular;
  font-weight: 600;
}
.globalize-localization-fjly-text{
  position:absolute;
  left:31.2%;
  top:61.64%;
  color:#606266;
  opacity:.8;
  font-size: 14px;
  line-height:18px;
  font-family: Lato,Lato-Regular;
  font-weight: 600;
}
.globalize-localization-flkf-text{
  position:absolute;
  left:46%;
  top:54.8%;
  color:#606266;
  opacity:.8;
  font-size: 14px;
  line-height:18px;
  font-family: Lato,Lato-Regular;
  font-weight: 600;
}
.globalize-localization-amstd-text{
  position:absolute;
  left:49.8%;
  top:52.64%;
  color:#606266;
  opacity:.8;
  font-size: 14px;
  line-height:18px;
  font-family: Lato,Lato-Regular;
  font-weight: 600;
}
.globalize-localization-sh-text{
  position:absolute;
  left:75.1%;
  top:64%;
  color:#606266;
  opacity:.8;
  font-size: 14px;
  line-height:18px;
  font-family: Lato,Lato-Regular;
  font-weight: 600;
}
.img-cus{
  position:relative;
}

@media screen and (max-width:1000px){
  .swiper-slide-content{
    height:27rem;
    width:33%;
  }
}
@media screen and (max-width:1000px){
  .swiper-slide-content{
    height:27rem;
    width:50%;
  }
}

@media screen and (max-width:500px){
  .swiper-slide-content{
    height:250px;
    width:50%;
  }
  .swiper-div{
    height:18rem;
    border: 1px solid #f0f0f1;
    margin:12px 12px;

  }
  .swiper-content{
    height:19rem;
    padding:10px 0 0 0;

  }
  .swiper-div:hover{
    height:18rem;
    border: 1px solid #ac2f41;
    color:#ac2f41;

  }
  .swiper-img-content{
    height:11rem;
    width:85%;
    margin:0 1%;
    padding:12px;
    transition:all .8s;

  }
  .swiper-img-content:hover{
    height:11rem;
    width:85%;
    margin:0 1%;
    padding:12px;
    transform:scale(1.1)

  }
  .swiper-img{
    width:100%;
    height:15.5rem;
  }
  .content{
    height:15.5rem;
  }
  .main{
    margin-top:0rem;
    padding:0;
  }
  .main>ul{
    background-color:#F3F4F8;
    padding:0;
  }
  .ul-li{
    width:100%;
    height:20.5rem;
    transition: none;
    margin:0;
    padding:0;
    border:none;
  }
  .ul-li:hover{
    transform: none;
  }
  .ul-li>a>img{
    height:16.5rem;
    width:100%;
    transition:all 1s;

  }
  .ul-li>a>img:hover{
    transform:scale(1.05);
  }

  ul{
    padding:0 1rem;
    background-color:#F3F4F8;
  }
  .title{
    width:90%;
    text-align: left;
    padding:20px 10px 0 10px;
    font-weight: bold;
    font-size: 14px;
    color:#041B3D
  }
  .more{
    width: 90%;
    text-align: left;
    padding: 6px 10px;
    font-size: 12px;
    color: #1B2126;
    display: inline-block;
    transition: all .5s;
    opacity: 0.5;
    line-height:14px;
  }
  .more>i{
    font-size: 14px;
    line-height:14px;
    font-weight: bolder;
  }
  .banner2{
    margin:0;
  }
  .solution-title{
    font-size:30px;
    font-weight:500;
    line-height:80px;
    text-align: center;
  }
  .globalize-map>img{
    width:80%;
    height:200px;
    margin:0 10%;
    padding-bottom:40px;
  }
  .globalize-title-title{
    padding:40px;
    height:40px;
    font-weight: 500;
    line-height:40px;
    text-align: center;
    font-size:28px;
  }
  .globalize-flex{
    display:flex;
    justify-content:center;
    flex-direction: column;
  }
  .globalize-flex>div{
    width:70%;
    display:flex;
    justify-content:left;
    padding: 0 40px 20px 20px;
  }
  .globalize-number{
    color:#5831ec;
    font-weight: 600;
    font-size: 18px;
  }
  .globalize-description{
    font-size: 16px;
    font-weight:200;
    line-height:26px;
    text-align: center;
  }
  .globalize-localization-elg{
    position:absolute;
    left:20%;
    top:66%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  .globalize-localization-fjly{
    position:absolute;
    left:30%;
    top:68%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  @keyframes localization {
    20%{
      transform:scale(1.1);
      border:1px solid rgba(255, 72, 0, 0.4);

    }
    40%{
      transform:scale(1.2);
      border:1px solid rgba(255, 72, 0, 0.2);
    }
    80%{
      transform:scale(1.3);
      border:1px solid rgba(255, 72, 0, .1);
    }

  }
  .globalize-localization-dot{
    left:20%;
    top:66%;
    width:5px;
    height:5px;
    border-radius: 50%;
    background-color:rgba(255, 72, 0, 1);
    margin:25%;
  }
  .globalize-localization-elg-text{
    position:absolute;
    left:23.2%;
    top:65.64%;
    color:#606266;
    opacity:.8;
    font-size: 14px;
    line-height:18px;
    font-family: Lato,Lato-Regular;
    font-weight: 600;
  }
  .globalize-localization-fjly-text{
    position:absolute;
    left:33.2%;
    top:67.64%;
    color:#606266;
    opacity:.8;
    font-size: 14px;
    line-height:18px;
    font-family: Lato,Lato-Regular;
    font-weight: 600;
  }
  .globalize-localization-fjly{
    position:absolute;
    left:30%;
    top:68%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  .globalize-localization-flkf{
    position:absolute;
    left:50%;
    top:61%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  .globalize-localization-amstd{
    position:absolute;
    left:48.5%;
    top:59%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  .globalize-localization-sh{
    position:absolute;
    left:74%;
    top:70%;
    width:10px;
    height:10px;
    border:1px solid rgba(255, 72, 0, 0.5);
    border-radius: 50%;
    background-color:rgba(255,72,0,.2);
    animation:2s localization infinite;
  }
  .globalize-localization-flkf-text{
    position:absolute;
    left:36%;
    top:60.8%;
    color:#606266;
    opacity:.8;
    font-size: 14px;
    line-height:18px;
    font-family: Lato,Lato-Regular;
    font-weight: 600;
  }
  .globalize-localization-amstd-text{
    position:absolute;
    left:52.8%;
    top:58.64%;
    color:#606266;
    opacity:.8;
    font-size: 14px;
    line-height:18px;
    font-family: Lato,Lato-Regular;
    font-weight: 600;
  }
  .globalize-localization-sh-text{
    position:absolute;
    left:77.1%;
    top:69.5%;
    color:#606266;
    opacity:.8;
    font-size: 14px;
    line-height:18px;
    font-family: Lato,Lato-Regular;
    font-weight: 600;
  }
  .img-cus-text{
    position:absolute;
    top:32%;
    left:10%;

    z-index:10000000;
  }
  .img-cus-text-1{
    font-size:24px;
    color:#fff;
    line-height:24px;
    text-align: left;
    font-weight: 500;
    letter-spacing:4px;
  }
  .img-cus-text-2{
    padding:10px 0 0 0;
    font-size:14px;
    color:#fff;
    line-height:14px;
    text-align: left;
    font-weight: 400;
    letter-spacing:3px;
  }
  .img-cus-text-3{
    width:200px;
    display:flex;
    flex-wrap:wrap;
    padding:10px 0;
    font-size:14px;
    color:#fff;
    line-height:14px;
    text-align: left;
    font-weight: 400;
    letter-spacing:3px;
  }
  .img-cus-text-3>div{
    padding:10px 10px 10px 0;
  }
  .img-cus-banner2-text{
    position:absolute;
    top:32%;
    left:10%;
    z-index:10000000;
  }
  .img-cus-banner2-text-1{
    font-size:20px;
    color:#fff;
    line-height:20px;
    text-align: left;
    font-weight: 500;
    letter-spacing:4px;
  }
  .img-cus-banner2-text-2{
    padding:10px 0 0 0;
    font-size:16px;
    color:#fff;
    line-height:16px;
    text-align: left;
    font-weight: 400;
    letter-spacing:2px;
  }
  .img-cus-banner2-text-3{
    width:350px;
    display:flex;
    flex-wrap:wrap;
    padding:10px 0;
    font-size:18px;
    color:#fff;
    line-height:30px;
    text-align: left;
    font-weight: 400;
    letter-spacing:3px;
  }
  .img-cus-banner2-text-3>a{
    margin-top: 20px;
    padding:10px 10px 10px 0;
    background-color:#ff4800;
    color:#fff;
    width:70px;
    height:10px;
    border: none;
    border-radius: 30px;
    line-height:10px;
    text-align:center;
    font-size: 13px;
  }
  .img-cus-banner2-text-3:hover{
    opacity:.8;
  }
  .img-cus-banner2-text-3>a>div{
    line-height:10px;
    text-align:center;
    width:100%;
    margin-left:7%;
  }



}
</style>