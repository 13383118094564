<template>
  <banner componentName="首页"></banner>
  <Content></Content>
  <Footer></Footer>
  <div>
    <div class="slider">
      <a href="/contactUs" class="contactUs-a">合作咨询</a>
    </div>
    <el-popover
        placement="left-end"
        :width="200"
        trigger="click"
        content="电话咨询：13757754696"
    >
      <template #reference>
        <div class="slider-contactUs"><i class="iconfont">&#xe61b;</i></div>
      </template>
    </el-popover>
    <div class="slider-toUp" @click="toUp"><i class="iconfont">&#xe669;</i></div>

  </div>


</template>

<script>
import banner from './banner'
import Content from './Content'
import Footer from './Footer'
export default {
  name: "Home",
  components:{
    banner,
    Content,
    Footer,

  },
  setup(){
   const toUp = function(){
     document.body.scrollTop = document.documentElement.scrollTop = 0;
   }

   return {
     toUp
   }
  }

}
</script>

<style scoped>
body{
  background-color:white;
}
.slider{
  position:fixed;
  top:55%;
  right:12px;
  background-color:rgb(255,46,0);
  width:50px;
  height:100px;
  z-index:100000000;
  border-radius:6px;
  color: #fff;
  display: block;
  font-size: 14px;
  text-align:center;
}
.slider-contactUs{
  position:fixed;
  top:69%;
  right:12px;
  background-color:rgb(255,46,0);
  width:50px;
  height:50px;
  z-index:100000000;
  border-radius:6px;
  color: #fff;
  display: block;
  text-align:center;
}
.slider-contactUs>i{
  font-size: 36px;
  line-height:36px;
  text-align: center;
  width:36px;
  height:36px;
  display: block;
  margin:6px auto;
}
.contactUs-a {
  width: 14px;
  color: #fff;
  display: block;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  margin: 10px auto;
}

.slider-toUp{
  position:fixed;
  top:77%;
  right:12px;
  background-color:#fff;
  width:50px;
  height:50px;
  z-index:100000000;
  border-radius:6px;
  color: rgba(0,0,0,.65);
  display: block;
  text-align:center;
  cursor: pointer;
  transition: all 1s;
  }
.slider-toUp>i{
  color: rgba(0,0,0,.65);
  display: block;
  font-size: 25px;
  text-align: center;
  text-decoration: none;
  margin: 10px auto;
}


</style>