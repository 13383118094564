<template>
<div class="banner">
  <div class="main">
    <div class="slogn-main"><router-link to="/">
      <img  src="/img/logo.png" alt="">
    </router-link></div>
    <div class="componentName">{{componentName}}</div>
    <div class="logo">
      <router-link to="/">
        <img class="logo-img" src="/img/logo.png" alt="">
      </router-link>
    </div>
    <div class="home"><router-link to="/">首页</router-link></div>
    <el-dropdown class="product">
    <span class="el-dropdown-link product"><router-link to="/productCenter">产品中心</router-link> <i class="iconfont showMore">&#xe665;</i>
    </span>
      <template #dropdown >
        <el-dropdown-menu class="el-scrollbar">
          <el-dropdown-item class="el-item"><router-link :to="'/products?id='+3">WIFI开关</router-link></el-dropdown-item>
          <el-dropdown-item class="el-item"><router-link :to="'/products?id='+2">自动重合闸（4G版）</router-link></el-dropdown-item>
          <el-dropdown-item class="el-item"><router-link :to="'/products?id='+1">WIFI自动重合闸</router-link></el-dropdown-item>
          <el-dropdown-item class="el-item"><router-link :to="'/products?id='+4">4P自动重合闸</router-link></el-dropdown-item>
          <el-dropdown-item class="el-item" ><router-link :to="'/products?id='+5">自动重合闸</router-link></el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>

    <div class="aboutUs"><router-link to="/aboutUs">关于我们</router-link></div>
    <div class="contactUs"><router-link to="/contactUs">联系我们</router-link></div>
    <div class="downloadArea"><router-link to="/download">下载专区</router-link></div>
    <div class="downloadArea"><a href="https://fiser.taobao.com">淘宝商城</a></div>
    <div class="downloadArea"><a href="https://puandajj.tmall.com">天猫商城</a></div>
    <i class="iconfont list-mobile" @click="newBannerOpen">&#xeaf1;</i>

    <div class="newBanner animate__animated animate__fadeIn">
      <div><i class="iconfont cancel-mobile " @click="newBannerClose" >&#xe621;</i></div>
      <div class="category" @click="newBannerClose" ><router-link to="/">首页</router-link></div>
      <div class="category" @click="newBannerClose" ><router-link to="/productCenter">产品中心</router-link></div>
      <div class="category"><router-link to="/aboutUs">关于我们</router-link></div>
      <div class="category"><router-link to="/contactUs">联系我们</router-link></div>
      <div class="category"><router-link to="/download">下载专区</router-link></div>
      <div class="category"><a href="https://fiser.taobao.com">淘宝商城</a></div>
      <div class="category"><a href="https://puandajj.tmall.com">天猫商城</a></div>
    </div>
  </div>

</div>
</template>

<script>
import 'animate.css';
export default {
 props:['componentName'],
  data() {
    return {
      activeNames: ['1']
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    }
  },
  setup(){
    const productShow = ()=>{
      const productNav = document.querySelector('.product-nav')
      if(productNav.style.visibility=='hidden'){
        productNav.style.visibility = 'visible';
      }
      else if(productNav.style.visibility=='visible'){
        productNav.style.visibility = 'hidden';
      }
      else{
        productNav.style.visibility='visible'
      }

    }
    const newBannerOpen = ()=>{
      const newBanner = document.querySelector('.newBanner')
      newBanner.style.display = 'block';
    }
    const newBannerClose = ()=>{
      const newBanner = document.querySelector('.newBanner')
      newBanner.style.display = 'none';
    }
    return {
      productShow,
      newBannerOpen,
      newBannerClose
    }
  }
}
</script>

<style scoped>

.banner{
  position:fixed;
  z-index:10000000;
  background-color:white;
  width:100%;
}
.main{
  display: flex;
  height:60px;
  border-bottom: 1px solid #eaeaea;

}
.logo{
  width:20%;
  position:relative;

}

.logo-img{
  height:30px;
  margin: 3px auto;
}
.home {
  width:6rem;
  height:70px;
  line-height:70px;
  padding:0 2.125rem;
  font-size:16px;
}
.logo {
  height:70px;
  line-height:70px;
  padding: 10px 0 0 50px;
}
.product {
  width:10rem;
  height:70px;
  line-height:70px;
  padding:0 0.5rem;
  font-size:1rem;;
  color: black;
  cursor: pointer;
}

.aboutUs {
  width:7rem;
  height:70px;
  line-height:70px;
  padding:0 2.125rem;
  font-size:1rem;
}
.contactUs {
  width:7rem;
  height:70px;
  line-height:70px;
  padding:0 0 0 2.125rem ;
  font-size:1rem;;
}
.downloadArea {
  width:7rem;
  height:70px;
  line-height:70px;
  padding:0 .5rem 0 2.125rem ;
  font-size:1rem;;
}

.el-scrollbar{
  width:10rem;
  background-color:white;
  border-radius: 2px;
  color:black;
}
.el-item{
  color:black;
}

a{
  text-decoration:none;
  color: black;
}
.list-mobile{
  display:none;
}
.newBanner{
  display:none;
}
.slogn-main{
  display:none;
}
.componentName{
  display:none;
}
@media screen and (max-width:1040px){
  .contactUs{
    display:none;
  }
}
@media screen and (max-width:870px){
  .downloadArea{
    display:none;
  }
}


/*适配移动端*/
@media screen and (max-width:500px){
 .logo{
   width:10rem;
   height:3.725rem;
   padding:.5rem;
   display:none;
 }
  .logo-img{
    width:10rem;
    height:3.725rem;
    color:white;
    display:none;
  }
  .home {
    width:3rem;
    height:4rem;
    line-height:70px;
    padding:0 2.125rem;
    font-size:20px;
  }
  .main{
    background-color: #0c5393;
    position:relative;
  }
  .home {
    width:6rem;
    height:70px;
    line-height:70px;
    padding:0 2.125rem;
    font-size:20px;
    display:none;
  }
  .logo {
    height:70px;
    line-height:70px;
    padding: 10px 0 0 50px;
    display:none;
  }
  .product {
    width:6rem;
    height:70px;
    line-height:70px;
    padding:0 2.125rem;
    font-size:1.25rem;;
    cursor: pointer;
    display:none;
  }

  .aboutUs {
    width:6rem;
    height:70px;
    line-height:70px;
    padding:0 2.125rem;
    font-size:1.25rem;
    display:none;
  }
  .contactUs {
    width:6rem;
    height:70px;
    line-height:70px;
    padding:0 0 0 2.125rem ;
    font-size:1.25rem;;
    display:none;
  }
  /*移动端菜单按钮*/
  .list-mobile{
    display:block;
    position:absolute;
    right:.6rem;
    width:3.125rem;
    height:3.125rem;
    font-size:2.525rem;
    color:white;
    line-height:3.125rem;
    text-align: center;
    margin:.5rem auto;
  }
  .newBanner{
    background-color:rgba(255,255,255,0.99);
    position:absolute;
    top:0;
    right:0;
    width:93%;
    height:100vh;
    z-index:10000000;

    animation-duration: .5s; /* don't forget to set a duration! */
    padding:0 1rem;

  }
  .cancel-mobile{
    display:block;
    position:absolute;
    right:.6rem;
    width:3.125rem;
    height:3.125rem;
    font-size:2rem;
    color:gray;
    font-weight: 100;
    line-height:3.125rem;
    text-align: center;
    margin:.5rem auto;
    padding-bottom:5rem;
  }
  .category:nth-child(2){
    font:1.3rem "Microsoft Yahei","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    font-size: 1.3rem;
    color:#333;
    font-weight:200;
    letter-spacing:1px;
    padding:5rem 2rem 1.1rem 2rem;
    border-bottom: 1px solid hsla(0,0%,91.8%,1)

  }
  .category{
    font:1.3rem "Microsoft Yahei","Helvetica Neue", Helvetica, STHeiTi, Arial, sans-serif;
    color:#333;
    font-weight:200;

    letter-spacing:1px;
    padding:1.1rem 2rem 1.1rem 2rem;;
    border-bottom: 1px solid hsla(0,0%,91.8%,1)

  }
  .downloadArea{
    display:none;
  }
  .slogn-main{
    display:block;
    height: 1rem;
    width:1rem;
    line-height:4rem;
    font-size: 1.2rem;
    text-align: center;
    color:rgba(256,256,256,.65);
    padding:0 1rem;
    font-weight: 200;
    letter-spacing:1px;
  }
  .slogn-main>a>img{
    width:6.5rem;
    height:1.3rem;
    margin:17px -6px;
  }
  .componentName{
    display:block;
    height: 4rem;
    width:20rem;
    line-height:4rem;
    font-size: 1.3rem;
    text-align: center;
    color:rgb(0,0,.65);
    padding:0 1rem;
    font-weight: 500;
    letter-spacing:1px;
  }
  /deep/.main{
    background-color:white;
  }
  /deep/.list-mobile{
    color:rgba(0,0,0,.65)
  }


}

</style>