import { createRouter, createWebHistory } from 'vue-router'
import Home from '/src/components/Home'
import ContactUs from "@/components/ContactUs";


const routes = [
  {
    path: '/',
    name: 'Home',
    meta:{
      loginRequire:false
    },
    component: Home
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: ContactUs
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',

    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/AboutUs')
  },
  {
    path: '/products',
    name: 'products',
    meta:{
      loginRequire:false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/products')
  },
  {
    path: '/download',
    name: 'download',
    meta:{
      loginRequire:false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/download')
  },
  {
    path: '/productCenter',
    name: 'productCenter',
    meta:{
      loginRequire:false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/productCenter')
  },
  {
    path: '/smartEleCase',
    name: 'smartEleCase',
    meta:{
      loginRequire:false
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/smartEleCase')
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router
